import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 0;
  background-size: cover;
  background-image: ${(props) => {
      if (props.background) {
        return `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.background});`;
      }
      return "linear-gradient(var(--bg-color), var(--bg-color));";
    }}
  .btn-mobile-wrapper {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 36px 0;
    & .participants-wrapper {
      margin: 0 !important;
    }
    .btn-mobile-wrapper {
      margin: 24px 20px 0;
      display: block;
      & > div {
        & > button {
          flex: 1;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
`;
