import { navigate } from "gatsby";
import React, { Fragment, useState } from "react";
import { HomepageButton } from "../HomepageButton/elements";
import { H3SmallMedium } from "../Typography";
import { Container } from "./elements";
import AnimatedText from "react-animated-text-content";
import ScrollTrigger from "react-scroll-trigger";

const HomepageLastSection = ({
  lastSectionBottomButtonLink,
  lastSectionBottomButtonText,
  lastSectionDescription,
  lastSectionTitle,
  lastSectionTopButtonLink,
  lastSectionTopButtonText,
}) => {
  const [effect, setEffect] = useState(false);

  return (
    <Fragment>
      <div style={{ height: "1px" }} />
      <ScrollTrigger onEnter={() => setEffect(true)} onExit={() => setEffect(false)}>
        <Container>
          {lastSectionTitle ? (
            <AnimatedText
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                scale: 1.1,
                ease: "ease-in-out",
              }}
              animationType="bounce"
              interval={0.06}
              duration={0.8}
              tag="p"
              style={{
                fontWeight: 600,
                fontSize: "42px",
                lineHeight: 1.25,
                margin: 0,
              }}
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              {lastSectionTitle}
            </AnimatedText>
          ) : null}

          {lastSectionDescription ? <H3SmallMedium>{lastSectionDescription}</H3SmallMedium> : null}

          {lastSectionTopButtonLink && lastSectionTopButtonText ? (
            <a href={lastSectionTopButtonLink} target="_blank">
              <HomepageButton className="transparent">{lastSectionTopButtonText}</HomepageButton>
            </a>
          ) : null}

          {lastSectionBottomButtonLink && lastSectionBottomButtonText ? (
            <a href={lastSectionBottomButtonLink} target="_blank">
              <HomepageButton className="transparent">{lastSectionBottomButtonText}</HomepageButton>
            </a>
          ) : null}
        </Container>
      </ScrollTrigger>
    </Fragment>
  );
};

export default HomepageLastSection;
