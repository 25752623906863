import React, { useEffect, useState } from "react";
import { Container } from "./elements";
import HomepageTitle from "../HomepageTitle";
import CommunityCardHolder from "../CommunityCardHolder";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { HomepageButton } from "../HomepageButton/elements";
import { navigate } from "gatsby";
import { AnimatedButton } from "../Buttons/elements";

const HomepageParticipants = ({ participants, title, buttonText, background }) => {
  const currentCards = participants.slice(0, 9);

  const [randomArray, setRandomArray] = useState([]);

  useEffect(() => {
    const temp = [];
    for (let i = 0; i < participants.length; i++) {
      let rand = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      if (participants[i].qafParticipantType?.[0]?.title === "Food") {
        rand = -1;
      }
      temp.push(rand);
    }
    setRandomArray(temp);
  }, []);

  return (
    <Container background={background}>
      <HomepageTitle title={title} buttonText={buttonText} buttonSlug="participants" />

      <CommunityCardHolder cards={currentCards} closeOverlay={false} randomArray={randomArray} cardsOrigin={participants} />

      <AnimationOnScroll
        className="btn-mobile-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AnimatedButton>
            <HomepageButton
              onClick={() => {
                navigate(`/participants`);
              }}
            >
              {buttonText}
            </HomepageButton>
          </AnimatedButton>
        </div>
      </AnimationOnScroll>
    </Container>
  );
};

export default HomepageParticipants;
