import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 0 75px;
  background-size: cover;
  background-image: ${(props) => {
      if (props.background) {
        return `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${props.background});`;
      }
      return "linear-gradient(var(--bg-color), var(--bg-color));";
    }}
    .btn-mobile-wrapper {
    display: none;
  }
  .swiper {
    min-width: 1440px;
    width: 100%;
    height: 425px;
  }

  .swiper-slide {
    cursor: var(--cursor-hover) 0 0, pointer !important;
    width: fit-content;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    object-fit: cover !important;
  }
  @media (max-width: 767px) {
    padding: 36px 0;
    .swiper {
      min-width: unset;
      width: 100%;
      height: 180px;
      margin-bottom: 36px;
    }
    .btn-mobile-wrapper {
      margin: 16px 20px 0;
      display: block;
      & > div {
        & > button {
          flex: 1;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
`;

export const SliderItem = styled.div`
  width: 505px;
  height: 425px;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 767px) {
    width: 220px;
    height: 180px;
  }
`;

export const InfoWrapper = styled.div`
  position: absolute;
  left: 39px;
  bottom: 32px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  width: unset !important;
  min-width: unset !important;
  padding-right: 16px;

  h5 {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  @media (max-width: 767px) {
    left: 15px;
    bottom: 15px;
    padding-right: 8px;
    h3 {
      font-size: 16px;
    }
    h5 {
      font-size: 12px;
    }
  }
`;
