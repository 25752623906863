import styled from "styled-components";

export const Container = styled.div`
  min-height: 139px;
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  margin: auto;
  @media (max-width: 767px) {
    height: unset;
  }
`;

export const Item = styled.div`
  flex: 1 0 calc(20% - 1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--primary);
  > h2 {
    color: var(--text-color);
  }
  > h3 {
    color: var(--black);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  @media (max-width: 767px) {
    height: 108px;
    flex: 1 0 calc(33% - 1px);
    > h2 {
      font-size: 36px;
    }
    > h3 {
      font-size: 15px;
    }
  }
`;
