import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper";
import { navigate } from "gatsby";
import {
  getFormatShortDayNoTimeZone,
  getTime,
  formatTime,
  getTwoDifferenceDayFormatNoTimeZone,
  getFormatTwoShortDayNoTimeZone,
} from "../../modules/time";
import HomepageTitle from "../HomepageTitle";
import { Container, InfoWrapper, SliderItem } from "./elements";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { H3Strong, H5BigMedium } from "../Typography";
import { AnimatedButton } from "../Buttons/elements";
import { HomepageButton } from "../HomepageButton/elements";
import { DownloadButton } from "../DownloadButton/elements";
import DownloadV2 from "../icons/DownloadV2";

const HomepageMusic = ({ events, title, buttonText, background, attachmentDownload }) => {
  const handleDate = (start, end) => {
    return new Date(start)?.getFullYear() !== new Date(end)?.getFullYear() ||
      new Date(start)?.getMonth() !== new Date(end)?.getMonth() ||
      !start ||
      !end
      ? getTwoDifferenceDayFormatNoTimeZone(start, end)
      : new Date(start)?.getDate() === new Date(end)?.getDate()
      ? getFormatShortDayNoTimeZone(start, true)
      : getFormatTwoShortDayNoTimeZone(start, end, true);
  };

  const handleTime = (start, end) => {
    if (!start && !end) return null;
    return end ? `${formatTime(getTime(start))}_${formatTime(getTime(end))}` : formatTime(getTime(start));
  };
  const images = events?.map((item) => {
    return {
      fixed: item.headerImage?.length ? item.headerImage[0]?.localFile?.childImageSharp?.fixed : null,
      data: {
        title: item.title,
        date: handleDate(item.eventStartDateTime, item.eventEndDateTime),
        time: handleTime(item.eventStartDateTime, item.eventEndDateTime),
        location: item.qafEventLocation?.length ? item.qafEventLocation[0]?.title : "",
      },
      link: `/event/${item?.slug}`,
    };
  });
  return (
    <Container background={background}>
      <HomepageTitle
        title={title}
        buttonText={buttonText}
        buttonSlug={attachmentDownload ?? "programme?event=music"}
        clearTheme
        attachmentDownload={attachmentDownload}
      />
      <AnimationOnScroll
        animatePreScroll={true}
        animateIn="animate__zoomIn"
        // animateOut="animate__zoomOut"
        duration={2}
        offset={40}
        animateOnce
      >
        <Swiper
          className="mySwiper"
          direction="horizontal"
          spaceBetween={20}
          centeredSlides={true}
          centerInsufficientSlides={true}
          modules={[Autoplay, Mousewheel]}
          speed={1500}
          loop={true}
          slidesPerView={"auto"}
          mousewheel={{
            forceToAxis: true,
          }}
          autoplay={{
            enabled: true,
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          {images?.map((image, imageIndex) => (
            <SwiperSlide key={imageIndex}>
              <SliderItem>
                <a href={image?.link}>
                  <img src={image?.fixed?.src} alt={`Quoz arts fest performance - ${image?.data?.title}`} />
                  <InfoWrapper>
                    <H3Strong
                      style={{
                        textAlign: "left",
                        color: "var(--text-color)",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {image.data?.title}
                    </H3Strong>
                    <H5BigMedium style={{ color: "var(--primary)", marginTop: "10px" }}>{image?.data?.date}</H5BigMedium>
                    <H5BigMedium
                      style={{
                        color: "var(--primary)",
                        textTransform: "uppercase",
                      }}
                    >
                      {image.data?.time}
                    </H5BigMedium>
                    <H5BigMedium style={{ color: "var(--mid)" }}>{image?.data?.location}</H5BigMedium>
                  </InfoWrapper>
                </a>
              </SliderItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </AnimationOnScroll>
      <AnimationOnScroll
        className="btn-mobile-wrapper"
        animateIn="animate__fadeInRight"
        animateOut="animate__fadeOutRight"
        duration={0.75}
        offset={100}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AnimatedButton>
            {attachmentDownload ? (
              <a href={attachmentDownload} download={"Programme"} target="_blank">
                <DownloadButton style={{ marginTop: "0" }}>
                  <DownloadV2 color="#101820" />
                  {buttonText}
                </DownloadButton>
              </a>
            ) : (
              <HomepageButton
                onClick={() => {
                  navigate(`/programme?event=music`);
                }}
              >
                {buttonText}
              </HomepageButton>
            )}
          </AnimatedButton>
        </div>
      </AnimationOnScroll>
    </Container>
  );
};

export default HomepageMusic;
