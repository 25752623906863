import React, { useState } from "react";
import { H2BigStrong, H3SmallMedium } from "../Typography";
import { Container, Item } from "./elements";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const HomepageInfo = ({ counterList }) => {
  const [isCountup, setIsCountup] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setIsCountup(true)}
      onExit={() => setIsCountup(false)}
    >
      <Container>
        {counterList?.map((item, index) => {
          return (
            <Item key={index}>
              {isCountup && (
                <H2BigStrong>
                  <CountUp
                    start={0}
                    end={item.number}
                    duration={0.75}
                    delay={0}
                  />
                  +
                </H2BigStrong>
              )}
              <H3SmallMedium>{item.title}</H3SmallMedium>
            </Item>
          );
        })}
      </Container>
    </ScrollTrigger>
  );
};

export default HomepageInfo;
