import React from "react";
import { HomepageButton } from "../HomepageButton/elements";
import { H2Strong } from "../Typography";
import { TitleWrapper } from "./elements";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { AnimatedButton } from "../Buttons/elements";
import DownloadV2 from "../../components/icons/DownloadV2";
import { DownloadButton } from "../DownloadButton/elements";

const HomepageTitle = ({ title, buttonText, buttonSlug, clearTheme, attachmentDownload }) => {
  return (
    <TitleWrapper>
      <AnimationOnScroll animateIn="animate__fadeInLeft" animateOut="animate__fadeOutLeft" duration={0.75} offset={100}>
        <H2Strong
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></H2Strong>
      </AnimationOnScroll>

      {buttonText ? (
        <AnimationOnScroll
          className="btn-desktop-wrapper"
          animateIn="animate__fadeInRight"
          animateOut="animate__fadeOutRight"
          duration={0.75}
          offset={100}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AnimatedButton>
              {attachmentDownload ? (
                <a href={attachmentDownload} download={"Programme"} target="_blank">
                  <DownloadButton>
                    <DownloadV2 color="#101820" />
                    {buttonText}
                  </DownloadButton>
                </a>
              ) : (
                <HomepageButton
                  onClick={() => {
                    if (clearTheme) {
                      if (typeof window !== "undefined") {
                        localStorage.removeItem("theme");
                      }
                      navigate(`/${buttonSlug}`);
                    } else {
                      navigate(`/${buttonSlug}`);
                    }
                  }}
                >
                  {buttonText}
                </HomepageButton>
              )}
            </AnimatedButton>
          </div>
        </AnimationOnScroll>
      ) : null}
    </TitleWrapper>
  );
};

export default HomepageTitle;

HomepageTitle.propsType = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
};
