import styled from "styled-components";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 100px;
  margin-bottom: 50px;
  color: var(--text-color);
  p {
    margin: 0;
    display: inline-block;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
    margin-bottom: 36px;
    h2 {
      font-size: 32px;
    }
    .btn-desktop-wrapper {
      display: none;
    }
  }
`;
