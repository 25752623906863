import styled from "styled-components";

export const HomepageButton = styled.button`
  font-family: Euclid Circular A;
  color: var(--btn-text);
  background: var(--btn-bg);
  border: none;
  padding: 5px 24px;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 150%;
  transition: all 0.3s;
  cursor: var(--cursor-hover) 0 0, pointer;
  &.transparent {
    color: var(--text-color);
    background: transparent;
    border: 2px solid var(--primary);
  }
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;
